/**
 * A common file to store client side tokens
 * for external services. These tokens are safe
 * to commit, as they are public tokens.
 */
export const LAUNCH_DARKLY_TOKENS = {
  DEVELOPMENT: '615706ea102d3625c403369f',
  PRODUCTION: '615706ae8b3909257634d441',
  TEST: '615706ae8b3909257634d440',
}
export const DATADOG_TOKENS = {
  LOGIN: {
    APPLICATION_ID: '93ef9cfa-0967-413e-84c5-e268bc5299e0',
    CLIENT_TOKEN: 'pub4f35cb3335e1d01a49f25d766dede4cb',
  },
  VOLTAPP: {
    APPLICATION_ID: '671cdf3d-b100-4535-b52d-c9b660b3888f',
    CLIENT_TOKEN: 'pub0cc6f0a21b46fd1b6eb6838f71ec40bb',
  },
  ADMIN: {
    APPLICATION_ID: '1b443c33-0f9e-4b3d-8e7f-edfc5feb5419',
    CLIENT_TOKEN: 'pub9df4a266c148984726d9f4eeae8990bb',
  },
  SITE: 'datadoghq.com',
}
