const watermark = document.createElement('div');
watermark.style.position = 'fixed';
watermark.style.top = '10px';
watermark.style.left = '250px';
watermark.style.pointerEvents = 'none';
watermark.style.zIndex = 99999;
watermark.style.padding = '10px';
watermark.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
watermark.style.color = 'white';
watermark.style.borderRadius = '4px';
watermark.innerHTML = 'DEV';
document.body.appendChild(watermark);