import bordersRaw from './borders.json'
import colors from './colors.json'
import fontStyles from './font-styles.json'
import radii from './radii.json'
import shadows from './shadows.json'
import spaceRaw from './space.json'

/*
 * Font Weights should be accessible via an array, but also as named keys
 */
const fontWeights = Object.values(fontStyles.fontWeights)
Object.keys(fontStyles.fontWeights).forEach((key) => {
  fontWeights[key] = fontStyles.fontWeights[key]
})

/*
 * Font Sizes should be accessible via an array, but also as named keys
 */
const fontSizes = Object.values(fontStyles.fontSizes)
Object.keys(fontStyles.fontSizes).forEach((key) => {
  fontSizes[key] = fontStyles.fontSizes[key]
})

type Space = {
  [K in keyof typeof spaceRaw['space']]: string
} & {
  asNums: Array<number>
}

function makeSpace(): Space {
  const space = spaceRaw.space

  // Mirror the space values as numbers, since by default they are strings with units -e.g. '8px'
  // Use Object.assign to get around typescript issues
  Object.assign(space, {
    asNums: Object.entries(space)
      .map(([k, v]) => [k, parseInt(v, 10)])
      .reduce((acc, [k, v]) => {
        acc[k] = v
        return acc
      }, {}),
  })

  return space as Space
}
const space = makeSpace()

const borders = Object.values(bordersRaw.borders)

interface FontWeight {
  [index: number]: number
  regular: number
  bold: number
  black: number
}

const theme = {
  colors,
  space,
  fontSizes,
  fontWeights: fontWeights as unknown as FontWeight,
  ...shadows,
  ...radii,
  fonts: {
    ui: 'Inter, sans-serif',
  },
  borders,
  zIndices: [0, 100, 200, 1000],
} as const

export default theme
