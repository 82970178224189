import './src/index.scss'
import { camelCase } from 'lodash'
import colorsRaw from './src/constants/colors.json'
import theme from './src/constants/theme'
import { Icons, StyledIcons } from './src/icons'
import { makeColorManager } from './src/utils/colorManager'
import { StyledProps } from './src/utils/styledSystem'

const walk = (node, cb) => {
  return Object.entries(node)
    .map(([key, value]) => {
      if (typeof value === 'object') {
        return {
          [key]: walk(value, cb),
        }
      }

      return cb(key, value)
    })
    .reduce((acc, curr) => {
      return (acc = {
        ...acc,
        ...curr,
      })
    }, {})
}

const colors = walk(colorsRaw, (key, value) => {
  return { [camelCase(key)]: value }
})

export * from './src/context'
export * from './src/components/Accordion'
export * from './src/components/ActionSheet'
export * from './src/components/ActivityIndicator'
export * from './src/components/Alert'
export * from './src/components/Badge'
export * from './src/components/Box'
export * from './src/components/Breadcrumbs'
export * from './src/components/Button'
export * from './src/components/ButtonDropdown'
export * from './src/components/Card'
export * from './src/components/ChatWindow'
export * from './src/components/Checkbox'
export * from './src/components/CommandBar'
export * from './src/components/Copyable'
export * from './src/components/CreatableDropdown'
export * from './src/components/DataSourcesPowerBox'
export * from './src/components/DateRangeDropdown'
export * from './src/components/DateTime'
export * from './src/components/DateTimePicker'
export * from './src/components/DateTimeRange'
export * from './src/components/DateTimeRangePicker'
export * from './src/components/DateTimeRangePickerControls'
export * from './src/components/DayPickerCalendar'
export * from './src/components/DebouncedSearch'
export * from './src/components/Download'
export * from './src/components/Dropdown'
export * from './src/components/DropdownMenu'
export * from './src/components/ErrorModal'
export * from './src/components/ErrorPages'
export * from './src/components/ExpandButton'
export * from './src/components/FileDropZone'
export * from './src/components/FileUploadButton'
export * from './src/components/FilesTable'
export * from './src/components/Flex'
export * from './src/components/Forms'
export * from './src/components/Graph'
export * from './src/components/Grid'
export * from './src/components/HorizontalBarChart'
export * from './src/components/IconButton'
export * from './src/components/Input'
export * from './src/components/JSONForm'
export * from './src/components/LinkTag'
export * from './src/components/Modal'
export * from './src/components/MonthPicker'
export * from './src/components/Navbar'
export * from './src/components/Notification'
export * from './src/components/PermissionedLink'
export * from './src/components/PowerBox'
export * from './src/components/Prompt'
export * from './src/components/ProtectedRoute'
export * from './src/components/QuickNavBar'
export * from './src/components/Radio'
export * from './src/components/RelativeDateTimeRangePicker'
export * from './src/components/ResponsiveContainer'
export * from './src/components/SegmentedToggle'
export * from './src/components/SideNavbar'
export * from './src/components/SiteSwitcher'
export * from './src/components/Skeleton'
export * from './src/components/Spacer'
export * from './src/components/Switch'
export * from './src/components/Table'
export * from './src/components/Tabs'
export * from './src/components/Text'
export * from './src/components/TextArea'
export * from './src/components/ThemeProvider'
export * from './src/components/TimeZoneContext'
export * from './src/components/Toast'
export * from './src/components/Tooltip'
export * from './src/components/TreeSelect'
export * from './src/components/VirtualizedTable'
export * from './src/components/Wizard'
/* PLOP_INJECT_EXPORT */

export { colors, theme, Icons, StyledIcons, makeColorManager }
export type { StyledProps }
