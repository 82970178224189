import '@babel/polyfill'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { createRoot } from 'react-dom/client'

import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter } from 'react-router-dom'
import {
  Card,
  Box,
  ResponsiveContainer,
  Modal,
  Text,
  ThemeProvider,
  Skeleton,
  theme,
  colors,
} from '@voltus/core-components'
import {
  datadogRum,
  initDDRum,
  initDDLogs,
  DATADOG_TOKENS,
} from '@voltus/datadog'
import { isTestEnv } from '@voltus/utils'
import 'normalize.css'
import './index.scss'

import { ENVIRONMENTS } from '@voltus/utils'
import App from './App'
import INJECTION from './constants/injection'
import { routes } from './routes/routes'

const NODE_ENV = process.env.NODE_ENV

// TODO: Someday this should be consolidated with admin
// which does the exact same thing just swaps out some tokens
if (NODE_ENV === ENVIRONMENTS.PROD && !isTestEnv()) {
  initDDRum({
    applicationId: DATADOG_TOKENS.LOGIN.APPLICATION_ID,
    clientToken: DATADOG_TOKENS.LOGIN.CLIENT_TOKEN,
    service: 'login',
  })
  initDDLogs({
    clientToken: DATADOG_TOKENS.LOGIN.CLIENT_TOKEN,
    service: 'login',
  })
  window.addEventListener('error', (evt) => {
    datadogRum.addError(evt.error, {
      unhandled: true,
      isGlobal: true,
    })
  })
}

function initApp() {
  routes.initRoutes()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  function ErrorFallback() {
    return (
      <ResponsiveContainer mt={3}>
        <Card as={Box} p={3}>
          <Text.Headline textSize="small">
            VoltApp has encountered an error!
          </Text.Headline>
          <Text.P mb={2}>
            <Text.Link fontSize={3} as="a" href="/">
              Click here
            </Text.Link>{' '}
            to try again
          </Text.P>
          <Text.P>
            If you continue to see this, please let us know by emailing{' '}
            <Text.Link fontSize={3} as="a" href="mailto:support@voltus.co">
              support@voltus.co
            </Text.Link>
          </Text.P>
        </Card>
      </ResponsiveContainer>
    )
  }
  if (INJECTION) {
    Modal.setAppElement(INJECTION)

    const root = createRoot(INJECTION)
    root.render(
      <React.StrictMode>
        <ErrorBoundary
          onError={(error) => {
            datadogRum.addError(error, {
              react: true,
              unhandled: true,
              isGlobal: true,
            })
          }}
          FallbackComponent={ErrorFallback}
        >
          <BrowserRouter basename={process.env.BASE_PATH}>
            <Skeleton.Theme
              color={colors.grays['15']}
              highlightColor={colors.grays['10']}
            >
              <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </Skeleton.Theme>
          </BrowserRouter>
        </ErrorBoundary>
      </React.StrictMode>
    )
  }
}

initApp()
