import * as React from 'react'
import { RouteConfig } from '@voltus/types'

interface Routes {
  routes: Array<RouteConfig>
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  initRoutes: () => void
}

export const routes: Routes = {
  routes: [],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initRoutes: function (this: Routes) {
    const Index = React.lazy(
      () => import(/* webpackChunkName: "profile" */ './Login')
    )

    this.routes = [
      {
        path: '*',
        Component: Index,
        Wrapper: React.Fragment,
      },
    ]
  },
}
